import React from "react"
import styled from "styled-components"

import HeaderBg from "@assets/how-to/banner_how_to_use.jpg"
import HeaderBgMobile from "@assets/how-to/banner_how_to_use_mobile.jpg"
import HowToEmployees from "@assets/how-to/how_to_use_01_employees.jpg"
import HowToManagers from "@assets/how-to/how_to_use_02_managers.jpg"
import HowToAe from "@assets/how-to/how_to_use_03_ae.jpg"

import AppLayout from "@components/layout/App"

import theme from "@assets/styles/theme"

const PAGE_HEADER_HEIGHT = "212px"

const PageHeader = styled.div`
  background-image: url(${HeaderBg});
  background-size: cover;
  background-position: center;
  height: ${PAGE_HEADER_HEIGHT};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;

  @media (max-width: 1023px) {
    background-image: url(${HeaderBgMobile});
    height: auto;
  }
`

const PageHeaderBoxes = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;

  @media (max-width: 1023px) {
    display: none;
  }
`

const PageHeaderGrey = styled.div`
  background-color: rgba(63, 63, 63, 0.9);
  color: ${theme.colors.grey.white};
  flex: 1;
  height: 150px;

  overflow: hidden;

  @media (max-width: 1023px) {
    background-color: transparent;
    padding: 0;
  }
`

const PageHeaderYellow = styled.div`
  background-color: ${theme.colors.secondary.yellow};
  width: 40%;
  height: 60px;

  @media (max-width: 1023px) {
    display: none;
  }
`

const PageHeaderTextContainer = styled.div`
  color: ${theme.colors.grey.white};
  width: min(1140px, calc(100vw - 32px));
  padding: 24px 0;
  z-index: 2;

  @media (max-width: 1023px) {
    width: 100%;
    text-align: center;
    padding: 0;
  }
`

const PageHeaderH2 = styled.h2`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 0 8px 0;

  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 21px;
    margin: 30px 0;
  }
`

const PageHeaderBody = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;

  @media (max-width: 1023px) {
    padding: 16px;
    background-color: ${theme.colors.grey.greyBackground};
    color: ${theme.colors.grey.textDark};
    font-size: 12px;
    line-height: 20px;
  }
`

const PageBody = styled.div`
  flex: 1;
  padding: 60px 0;
  background-color: ${theme.colors.grey.white};
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1023px) {
    padding: 24px 0 32px 0;
  }
`

const BoxesGrid = styled.div`
  display: grid;
  width: min(1140px, calc(100vw - 32px));
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 16px;

  @media (max-width: 1023px) {
    grid-template-columns: 100%;
    grid-template-rows: 1fr 1fr 1fr;
    row-gap: 16px;
  }
`

const HowToBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.primary.blueLightest};
  text-align: center;
`

const HowToBoxText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 24px 16px;

  @media (max-width: 1023px) {
    padding: 16px 8px;
  }

  h1 {
    color: ${theme.colors.primary.blueDark};
    margin: 0 0 8px 0;
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;

    @media (max-width: 1023px) {
      font-size: 18px;
      line-height: 21px;
    }
  }

  p {
    color: ${theme.colors.grey.textDark};
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;

    @media (max-width: 1023px) {
      font-size: 12px;
      line-height: 20px;
    }
  }
`

const MobileBr = styled.br`
  display: none;
  @media (max-width: 1023px) {
    display: block;
  }
`

const howToBoxes = [
  {
    image: HowToEmployees,
    title: "Employees",
    body: "to have clarity around various career opportunities",
  },
  {
    image: HowToManagers,
    title: "Managers",
    body:
      "to have a more effective and consistent way to have career conversations",
  },
  {
    image: HowToAe,
    title: "Alliant Energy",
    body:
      "to have a consistent career framework that will strengthen its ability to attract, retain and engage talent",
  },
]

const Page = props => {
  return (
    <AppLayout>
      <PageHeader>
        <PageHeaderBoxes>
          <PageHeaderGrey />
          <PageHeaderYellow />
        </PageHeaderBoxes>
        <PageHeaderTextContainer>
          <PageHeaderH2>How to Use This Guide</PageHeaderH2>
          <PageHeaderBody>
            This document serves as your guide to explore your career within
            Alliant Energy <br />
            <MobileBr /> It is meant to help:
          </PageHeaderBody>
        </PageHeaderTextContainer>
      </PageHeader>
      <PageBody>
        <BoxesGrid>
          {howToBoxes.map(box => (
            <HowToBox>
              <img src={box.image} alt={box.title} style={{ width: "100%" }} />
              <HowToBoxText>
                <h1>{box.title}</h1>
                <p>{box.body}</p>
              </HowToBoxText>
            </HowToBox>
          ))}
        </BoxesGrid>
      </PageBody>
    </AppLayout>
  )
}

export default Page
